import styled from 'styled-components'

export const RightTriangleBorder = styled.div`
	position: relative;
	border-top: 100px solid #F6F9F9;
	border-right: 100vw solid white;
	@media(max-width: 600px) {
		border-top: 50px solid #F6F9F9;
		border-right: 100vw solid white;
	}
`
export const LeftTriangleBorder = styled.div`
	position: relative;
	border-top: 100px solid #F6F9F9;
	border-left: 100vw solid white;
	@media(max-width: 600px) {
		border-top: 50px solid #F6F9F9;
		border-left: 100vw solid white;
	}
`

export const ProductHeroTriangle = styled.div`
	position: relative;
	z-index: 3;
	margin-top: -130px;
	border-bottom: 130px solid #213F51;
	border-left: 100vw solid rgba(255, 255, 255, 0.001);
	@media(max-width: 768px) {
		margin-top: -50px;
		border-bottom: 50px solid #213F51;
		border-left: 100vw solid rgba(255, 255, 255, 0.001);
	}
`
export const UsecaseHeroTriangle = styled.div`
	position: relative;
	z-index: 3;
	margin-top: -120px;
	border-bottom: 120px solid #ffffff;
	border-left: 100vw solid rgba(255, 255, 255, 0.001);
	@media(max-width: 768px) {
		margin-top: -50px;
		border-bottom: 50px solid #ffffff;
		border-left: 100vw solid rgba(255, 255, 255, 0.001);
	}
`