import styled from 'styled-components'

export const TwoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: ${props=>props.alignItems};
  grid-column-gap: 40px;

  max-width: 1120px;
  margin: 0 auto;
  @media(max-width: 1024px){
    grid-column-gap: 20px;    
  }
  @media(max-width: 768px){
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 16px;
    max-width: 600px;
    width: 100%;
    margin: 30px auto;
  }  
`
TwoGrid.defaultProps={
  alignItems: 'center'
}

export const ThreeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;

  max-width: 1120px;
  margin: 50px auto 0;
  @media(max-width: 768px){
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 15px;
    max-width: 600px;
    width: 100%;
    margin: 30px auto;
  }  
`

export const FourGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 27px;

  max-width: 1120px;
  margin: 50px auto 0;
  @media(max-width: 1024px){
    grid-column-gap: 18px;    
  }
  @media(max-width: 768px){
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 13px;
    grid-row-gap: 16px;
    max-width: 600px;
    width: 100%;
    margin: 30px auto;
  }  
`
