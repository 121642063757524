import React from 'react'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'

const SEO = ({title, description }) => {
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        < meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"/>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />
        <meta property="og:title" content="Biometric Vision - Facial Recognition API Technology" />
        <meta property="og:site_name" content="Biometric Vision" />
        <meta property="og:url" content="https://biometricvision.com/" />
        <meta property="og:description" content="Integrate with Biometric Vision's next generation AI-powered algorithm in minutes and supercharge your facial recognition apps." />
        <meta property="og:type" content="business.business" />
        <meta property="og:image" content="blob:https://biometricvision.com/ef7836b1-d4ba-4646-b2d0-3b9644098942" />
        <link href='https://fonts.googleapis.com/css?family=Montserrat' rel='stylesheet' />
        <link href="https://fonts.googleapis.com/css?family=Muli" rel="stylesheet" />
      </Helmet>
    </div>
  )
}

export default SEO