import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { cleanPath } from '../utils/path'

const ContentGrid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  padding-bottom: 40px;
  & h5 {
    font-size: 24px !important;
  }
  & p {
    font-size: 24px;
    color: #122C3C;
    margin-top: 10px;
  }
  & a:nth-child(1) {
    margin-right: 20px;
  }

  @media(max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 20px;
    max-width: 350px;
    width: 100%;
    margin: auto;
    & div {
      text-align: center !important;
    }
    & svg {
      margin-left: 0px;
      margin-right: 20px;
    }
    & a:nth-child(1) {
      marign: 0;
    }
    & a {
      width: 100%;
      max-width: 350px;
      margin: auto;
      & button {
        margin-top: 20px;
        width: 100%;
      }
    }
  }
`

const CtaSection = ({bgClassName}) => {
  return (
    <StaticQuery
      query ={graphql`
        query CtaQuery {
          allMarkdownRemark (
            filter: { fileAbsolutePath: {regex: "/data/ctadata/"}}
          ) {
            edges {
              node {
                frontmatter {
                  title
                  description
                  touchButton {
                    text
                    link
                    linkNewWindow
                  }
                  startButton {
                    text
                    link
                    linkNewWindow
                  }
                }
              }
            }
          }
        }
      `}
      render={data=>(
        <section className={`section ${bgClassName}`}>
          <div className="container">
            <ContentGrid>
              <div>
                <h5>{data.allMarkdownRemark.edges[0].node.frontmatter.title}</h5>
                <p>{data.allMarkdownRemark.edges[0].node.frontmatter.description}</p>
              </div>
              <div>
                {data.allMarkdownRemark.edges[0].node.frontmatter.touchButton.linkNewWindow?(
                  <a href={cleanPath(data.allMarkdownRemark.edges[0].node.frontmatter.touchButton.link)} target="_blank" rel="noreferrer">
                    <button className="is-second">{data.allMarkdownRemark.edges[0].node.frontmatter.touchButton.text}</button>
                  </a>
                ):(
                  <Link to={cleanPath(data.allMarkdownRemark.edges[0].node.frontmatter.touchButton.link)}>
                    <button className="is-second">{data.allMarkdownRemark.edges[0].node.frontmatter.touchButton.text}</button>
                  </Link>
                )}
                {data.allMarkdownRemark.edges[0].node.frontmatter.startButton.linkNewWindow?(
                  <a href={cleanPath(data.allMarkdownRemark.edges[0].node.frontmatter.startButton.link)} target="_blank" rel="noreferrer">
                    <button className="is-primary">{data.allMarkdownRemark.edges[0].node.frontmatter.startButton.text}</button>
                  </a>
                ):(
                  <Link to={cleanPath(data.allMarkdownRemark.edges[0].node.frontmatter.startButton.link)}>
                    <button className="is-primary">{data.allMarkdownRemark.edges[0].node.frontmatter.startButton.text}</button>
                  </Link>
                )}
              </div>
            </ContentGrid>
          </div>
        </section>
      )}
    />
  )
}

export default CtaSection
